<template>
  <div class="home">
    <custom-section class="section pa-0 pa-md-5">
      <NavbarOne
        class="d-md-none d-lg-none"
        :showNavbar="showNavbar"
        :showCart="showCart"
        :showCartDesktop="showCartDesktop"
        :showSearch="showSearch"
        :showLogo="showLogo"
       
      >
      </NavbarOne>
      <div class="mb-6 corp-m">
        <div class="payment-info">
          <v-data-table
            :options.sync="options"
            :headers="headers"
            :items="datas"
            class="table-officecare-company pa-3"
            :mobile-breakpoint="0"
            hide-default-footer
            :loading="myloadingvariable"
            :items-per-page="-1"
            :custom-sort="customSort"
          >
            <template v-slot:top>
              <v-row
                class="d-flex flex align-center justify-space-between px-0 py-0"
              >
                <v-col cols="12" md="8" class="d-flex align-center pl-6">
                  <localized-link to="/company/office-care/add">
                    <v-btn color="primary" dark class="button">
                      {{ $t("add") }}
                    </v-btn>
                  </localized-link>
                </v-col>
                <v-col
                  cols="12"
                  md="4"
                  class="d-flex align-center justify-end pr-0"
                >
                  <v-text-field
                    class="searchField fontSet pl-3 pr-3"
                    outlined
                    dense
                    hide-details
                    v-model="search"
                    :label="$t('search') + ' ...'"
                    append-icon="mdi-magnify"
                  ></v-text-field>

                  <div>
                    <v-select
                      class="footerHide"
                      v-model="perPage"
                      :items="itemShowing"
                    ></v-select>
                  </div>
                </v-col>
              </v-row>
            </template>
            <template v-slot:item="{ item }">
              <tr class="hd-oc-sticky">
                <td valign="top" class="pt-2 sticky-oc1 text-center">
                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        small
                        @click="editItem(item)"
                        v-bind="attrs"
                        v-on="on"
                        style="color: #00a4b3"
                      >
                        mdi-file-document-outline
                      </v-icon>
                    </template>
                    <span>{{ $t("detail") }}</span>
                  </v-tooltip>
                </td>
                <td valign="top" class="pt-2 sticky-oc2">
                  {{ formatDate(item.booking_date) }}
                </td>
                <td valign="top" class="pt-2">{{ item.registration_group }}</td>
                <!-- <td valign="top" class="pt-2">{{ item.kode }}</td> -->
                <td valign="top" class="pt-2">{{ item.plant }}</td>
                <td valign="top" class="pt-2">{{ item.type }}</td>
                <td valign="top" class="pt-2">
                  <div class="items-product">
                    {{
                      item[`item_${$i18n.locale}`] == null
                        ? item[`item_en`]
                        : item[`item_${$i18n.locale}`]
                    }}
                  </div>
                </td>
                <td valign="top" class="pt-2">{{ item.status }}</td>
              </tr>
            </template>
            <template v-slot:footer>
              <v-row
                class="d-flex flex align-center justify-space-between footer-payment"
              >
                <v-col cols="12" md="8">
                  <p class="footer-showing">
                    showing {{ itemShowStart }} to {{ itemShowStop }} of
                    {{ itemsTotal }} entries
                  </p>
                </v-col>
                <v-col cols="12" md="4" class="d-flex justify-end">
                  <custom-pagination
                    @changePage="(val) => (page = val)"
                    :totalPage="itemLength"
                    :activePage="page"
                  />
                </v-col>
              </v-row>
            </template>
          </v-data-table>
          <!-- </div> -->
          <!-- </v-col>
      </v-row> -->
          <!-- start modal popup daily referral month (dialog_referral)-->
          <template>
            <v-row justify="center">
              <v-dialog v-model="dialog_referral">
                <v-card>
                  <v-card-text class="px-5 py-2">
                   
                        <div class="payment-info">
                          <v-data-table
                            :headers="headersPatient"
                            :items="datasPatient"
                            class="table-officecare-company"
                            :class="{
                              'table-stick-book-popup':
                                datasPatient.length > 15,
                            }"
                            :mobile-breakpoint="0"
                            hide-default-footer
                            :loading="myloadingvariablePopup"
                            :items-per-page="-1"
                          >
                            <template v-slot:top>
                              <v-card-title class="pa-0">
                                <v-col cols="12" md="8" no-gutters>
                                  <h4 class="package-title">
                                    {{ $t("page.employee.detail_office_care") }}
                                  </h4>
                                </v-col>
                                <v-col
                                  cols="12"
                                  md="4"
                                  no-gutters
                                  class="d-flex align-center justify-end pr-0"
                                >
                                  <v-text-field
                                    class="searchField fontSet pr-3"
                                    outlined
                                    dense
                                    hide-details
                                    v-model="searchPopup"
                                    :label="$t('search') + ' ...'"
                                    append-icon="mdi-magnify"
                                  ></v-text-field>
                                  <div>
                                    <v-select
                                      class="footerHide pt-0"
                                      v-model="perPagePopup"
                                      :items="itemShowingPopup"
                                      hide-details
                                    ></v-select>
                                  </div>
                                </v-col>
                              </v-card-title>
                            </template>
                            <template v-slot:item="{ item }">
                              <tr class="sticky-popup-hd">
                                <td valign="top" class="pt-2 sticky-popup-oc1">
                                  <template v-if="item.status != 'REGISTERED'">
                                    <p>-</p>
                                  </template>
                                  <template v-else>
                                    <v-tooltip top>
                                      <template
                                        v-slot:activator="{ on, attrs }"
                                      >
                                        <v-icon
                                          small
                                          @click="cancelBooking(item)"
                                          v-bind="attrs"
                                          v-on="on"
                                          style="color: #00a4b3"
                                        >
                                          mdi-delete
                                        </v-icon>
                                      </template>
                                      <span>{{ $t("cancel") }}</span>
                                    </v-tooltip>
                                    <v-tooltip top>
                                      <template
                                        v-slot:activator="{ on, attrs }"
                                      >
                                        <v-icon
                                          class="ml-3"
                                          small
                                          @click="rescheduleData(item)"
                                          v-bind="attrs"
                                          v-on="on"
                                          style="color: #00a4b3"
                                        >
                                          mdi-calendar-range
                                        </v-icon>
                                      </template>
                                      <span>{{
                                        $t("page.profile.reschedule")
                                      }}</span>
                                    </v-tooltip>
                                  </template>
                                </td>
                                <td valign="top" class="pt-2 sticky-popup-oc12">
                                  {{ item.registration_group }}
                                </td>
                                <td valign="top" class="pt-2">
                                  <template v-if="item.booking_date == '-'">
                                    {{ item.booking_date }}
                                  </template>
                                  <template v-else>
                                    {{ formatDate(item.booking_date) }}
                                  </template>
                                </td>
                                <td valign="top" class="pt-2">
                                  <!-- {{ item.registration_number }} -->
                                  <template v-if="item.status == 'COMPLETED'">
                                    <a
                                      href="javascript:void(0);"
                                      @click="openDialogResult(item)"
                                      >{{ item.order_number }}</a
                                    >
                                    <!-- <a
                                  v-else
                                  href="javascript:void(0);"
                                  @click="
                                    downloadLab(
                                      item.order_number,
                                      'simple',
                                      false
                                    )
                                  "
                                  >{{ item.order_number }}</a
                                > -->
                                  </template>
                                  <template v-else>
                                    {{ item.order_number }}
                                  </template>
                                </td>
                                <td valign="top" class="pt-2">
                                  {{ item.id_number }}
                                </td>
                                <td valign="top" class="pt-2">
                                  {{ item.patient }}
                                </td>
                                <td valign="top" class="pt-2">
                                  {{ item.department }}
                                </td>
                                <td valign="top" class="pt-2">
                                  {{ item.job_position }}
                                </td>
                                <td valign="top" class="pt-2">
                                  {{ item.plant }}
                                </td>
                                <td valign="top" class="pt-2">
                                  {{ item.type }}
                                </td>
                                <td valign="top" class="pt-2">
                                  {{ item.item_name }}
                                </td>
                                <td valign="top" class="pt-2">
                                  {{ item.status }}
                                </td>
                                <td
                                  valign="top"
                                  class="pt-2 sticky-popup-oc2"
                                  align="right"
                                >
                                  <template v-if="item.amount_total == '-'">
                                    {{ item.amount_total }}
                                  </template>
                                  <template v-else>
                                    {{ formatPrice(item.amount_total) }}
                                  </template>
                                </td>
                              </tr>
                            </template>
                            <template v-slot:footer>
                              <v-row
                                class="d-flex flex align-center justify-space-between footer-payment"
                              >
                                <v-col cols="12" md="8">
                                  <p class="footer-showing">
                                    showing {{ itemShowStartPopup }} to
                                    {{ itemShowStopPopup }} of
                                    {{ itemsTotalPopup }} entries
                                  </p>
                                </v-col>
                                <v-col
                                  cols="12"
                                  md="4"
                                  class="d-flex justify-end"
                                >
                                  <custom-pagination
                                    @changePage="(val) => (pagePopup = val)"
                                    :totalPage="itemLengthPopup"
                                    :activePage="pagePopup"
                                  />
                                </v-col>
                              </v-row>
                            </template>
                          </v-data-table>
                        </div>
                  </v-card-text>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="primary darken-1" text @click="closeEdit()">
                      {{ $t("close") }}
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </v-row>
          </template>
          <DialogOrderDetail
            v-if="dialog_order"
            :item="dataDialogOrder"
            :tampilkan="dialog_order"
            @toggleDialog="(is_show) => toggleDialogOrder(is_show)"
          />
          <v-dialog
            v-model="dialog_reschedule"
            max-width="500px"
            persistent
            @click:outside="closeReschedule"
          >
            <DialogRescheduleCompany
              :data="dataDialogReschdule"
              @closeReschedule="closeReschedule()"
              @updateData="updateOrderList()"
            >
            </DialogRescheduleCompany>
          </v-dialog>
        </div>
      </div>
    </custom-section>
  </div>
</template>

<script>
// import TabContent from "@/components/v2/tabs/index.vue";
import API from "@/service/api_service.js";
import Auth from "@/service/auth_service";
import moment from "moment";
import NavbarOne from "@/components/v2/topbar/navbar_one.vue";
import DialogOrderDetail from "@/components/v3/results/index.vue";
import DialogRescheduleCompany from "@/components/v2/dialog/dialogRescheduleCompany.vue";
import CustomPagination from "@/components/v3/pagination.vue";
import CustomSection from "@/components/v2/section/index.vue";

export default {
  components: {
    NavbarOne,
    DialogOrderDetail,
    CustomPagination,
    DialogRescheduleCompany,
    CustomSection,
  },
  data: () => ({
    page: 1,
    pagePopup: 1,
    options: null,
    itemsTotal: 0,
    itemsTotalPopup: 0,
    itemLength: 1,
    itemLengthPopup: 1,
    itemShowStart: 0,
    itemShowStartPopup: 0,
    itemShowStop: 0,
    itemShowStopPopup: 0,
    itemShowing: [
      { value: 25, text: "25" },
      { value: 50, text: "50" },
      { value: 70, text: "70" },
      { value: 100, text: "100" },
    ],
    itemShowingPopup: [
      { value: 25, text: "25" },
      { value: 50, text: "50" },
      { value: 70, text: "70" },
      { value: 100, text: "100" },
    ],
    perPage: 25,
    perPagePopup: 25,
    myloadingvariable: true,
    myloadingvariablePopup: true,
    showNavbar: true,
    showCart: false,
    showCartDesktop: false,
    showSearch: false,
    showLogo: true,
    search: "",
    searchPopup: "",
    dialogDelete: false,
    formHasError: false,
    headers: [],
    datas: [],
    headersPatient: [],
    datasPatient: [],
    dialog_referral: false,
    editedIndex: -1,
    registration_group: null,
    kode: null,
    booking_date: null,
    status: null,
    errorMessages: "",
    defaultItem: {
      registration_group: "",
      kode: "",
      booking_date: "",
      status: "",
    },
    getAuth: null,
    tableInvoice: [],
    dialog_invoice: false,
    dataDialogOrder: {
      order_id: null,
      list: [],
    },
    dialog_order: false,
    getDataProfile: "",
    idTemp: "",
    dataDialogReschdule: {},
    dialog_reschedule: false,
  }),
  computed: {
    // get data profile from store
    dataProfile() {
      return this.$store.state.profileData;
    },
  },
  created() {
    this.headersPatient = [
      {
        text: this.$t("page.profile.actions"),
        align: "center",
        value: "actions",
        sortable: false,
        fixed: true,
        class: "sticky-popup-hoc1",
      },
      {
        text: this.$t("page.profile.booking_batch"),
        align: "center",
        value: "registration_group",
        class: "sticky-popup-hoc12",
      },
      {
        text: this.$t("field.booking_date"),
        value: "booking_date",
        align: "center",
      },

      {
        text: this.$t("page.referral.order_num"),
        sortable: false,
        value: "order_number",
        align: "center",
      },
      {
        text: this.$t("page.profile.patient_id"),
        sortable: false,
        value: "id_number",
        align: "center",
      },
      {
        text: this.$t("page.profile.patient_name"),
        value: "patient",
        sortable: false,
        align: "center",
      },
      {
        text: this.$t("field.department"),
        value: "department",
        sortable: false,
        align: "center",
      },
      {
        text: this.$t("field.jobpost"),
        value: "job_position",
        sortable: false,
        align: "center",
      },
      {
        text: this.$t("field.branch"),
        sortable: false,
        value: "plant",
        align: "center",
      },
      {
        text: this.$t("field.type"),
        sortable: false,
        value: "type",
        align: "center",
      },
      {
        text: this.$t("page.profile.item_name"),
        value: "item_name",
        sortable: false,
        align: "center",
      },
      {
        text: this.$t("page.profile.status"),
        value: "status",
        sortable: false,
        align: "center",
      },
      {
        text: this.$t("page.profile.amount"),
        value: "amount_total",
        sortable: false,
        class: "sticky-popup-hoc2",
        align: "center",
      },
    ];
    this.headers = [
      {
        text: this.$t("page.profile.actions"),
        value: "actions",
        sortable: false,
        fixed: true,
        class: "sticky-hoc1",
        align: "center",
        width: "76px",
      },
      {
        text: this.$t("field.booking_date"),
        value: "booking_date",
        align: "center",
        class: "sticky-hoc2",
      },
      {
        text: this.$t("page.profile.booking_batch"),
        value: "registration_group",
        fixed: true,
        align: "center",
      },

      {
        text: this.$t("field.branch"),
        value: "branch_name",
        sortable: false,
        align: "center",
      },
      {
        text: this.$t("field.type_of_test"),
        value: "type",
        sortable: false,
        align: "center",
      },
      {
        text: this.$t("field.item_test"),
        value: "item_name",
        sortable: false,
        align: "center",
      },
      {
        text: this.$t("page.profile.status"),
        value: "status",
        align: "center",
      },
    ];
    if (this.dataProfile) {
      this.getDataEmployee();
    }
  },
  mounted() {
    this.getAuth = Auth.getTypeUser();
  },
  watch: {
    dialogDelete(val) {
      val || this.closeDelete();
    },
    page: function () {
      this.getDataEmployee();
    },
    perPage: function () {
      if (this.page != 1) {
        this.page = 1;
      } else {
        this.getDataEmployee();
      }
    },
    pagePopup: function () {
      this.getInitialRevenuePopup(this.idTemp);
    },
    perPagePopup: function () {
      if (this.pagePopup != 1) {
        this.pagePopup = 1;
      } else {
        this.getInitialRevenuePopup(this.idTemp);
      }
    },
    search: function () {
      this.page = 1;
      this.getDataEmployee();
    },
    searchPopup: function () {
      this.pagePopup = 1;
      this.getInitialRevenuePopup(this.idTemp);
    },
  },

  methods: {
    customSort(items, index, isDesc) {
      items.sort((a, b) => {
          if (index[0]=='booking_date') {
            if (!isDesc[0]) {
                return new Date(b[index]) - new Date(a[index]);
            } else {
                return new Date(a[index]) - new Date(b[index]);
            }
          }
          else {
            if(typeof a[index] !== 'undefined'){
              if (!isDesc[0]) {
                 return a[index].toLowerCase().localeCompare(b[index].toLowerCase());
              }
              else {
                  return b[index].toLowerCase().localeCompare(a[index].toLowerCase());
              }
            }
          }
      });
      return items;
    },
    async updateOrderList() {
      this.$emit("updateOrderList");
    },
    formatPrice(price) {
      return Intl.NumberFormat().format(price);
    },
    formatDate(date) {
      if (!date) return null;
      return moment(date)
        .locale(this.$i18n.locale)
        .format("DD-MMM-YYYY")
        .toUpperCase();
    },
    toast(status, messages) {
      this.$swal
        .mixin({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", this.$swal.stopTimer);
            toast.addEventListener("mouseleave", this.$swal.resumeTimer);
          },
        })
        .fire({
          icon: status,
          title: messages,
        });
    },
    async cancelBooking(item) {
      this.$swal({
        text: this.$t("field.cancel_order"),
        showCancelButton: true,
        confirmButtonColor: "#1A428A",
        cancelButtonColor: "#3085d6",
        confirmButtonText: this.$t("yes"),
        cancelButtonText: this.$t("no"),
      }).then((result) => {
        if (result.isConfirmed) {
          var dataReady = {
            detail_ids: [item.id],
          };
          API.post(
            `${process.env.VUE_APP_API_TRUST}employee/cancel-booking`,
            {
              "Content-Type": "application/json",
              Authorization: `Bearer ${Auth.getToken()}`,
            },
            dataReady
          ).then((res) => {
            if (res.statusCode === 200) {
              window.location.href = `/company/office-care`;
              this.toast("success", this.$t("field.success_cancel"));
            }
          });
        }
      });
    },
    async rescheduleData(data) {
      this.dialog_reschedule = true;
      this.dataDialogReschdule = data;
    },
    closeReschedule() {
      this.dataDialogReschdule = {};
      this.dialog_reschedule = false;
    },

    async editItem(item) {
      this.getInitialRevenuePopup(item.id);
      this.dialog_referral = true;
    },
    async getInitialRevenuePopup(getDatas) {
      this.idTemp = getDatas;
      try {
        this.myloadingvariablePopup = true;
        // pagination showing start of entries
        let startPagePopup = (this.pagePopup - 1) * this.perPagePopup;
        let showPagePopup = this.perPagePopup;
        this.itemShowStartPopup = startPagePopup + 1;
        this.itemShowStopPopup = startPagePopup + showPagePopup;

        const res = await API.get(
          `${process.env.VUE_APP_API_TRUST}employee/get-booking-detail?query=${this.searchPopup}&id=${getDatas}&offset=${startPagePopup}&per_page=${showPagePopup}`,
          {
            "Content-Type": "application/json",
            Authorization: `Bearer ${Auth.getToken()}`,
          }
        );

        // Check result exist
        if (res.results) {
        
          var new_data = [];
          res.results.map(function (e) {
            var bookingDateFormat = "";
            if (e.booking_date !== null) {
              bookingDateFormat = moment(e.booking_date)
                .format("DD-MMM-YYYY")
                .toUpperCase();
            } else {
              bookingDateFormat = "-";
            }

            new_data.push({
              id: typeof e.id !== "undefined" ? e.id : "",
              expired_days: e.expired_days !== null ? e.expired_days : "-",
              max_reschedule:
                e.max_reschedule !== null ? e.max_reschedule : "-",
              reschedule_attempt:
                e.reschedule_attempt !== null ? e.reschedule_attempt : "-",
              start_reschedule:
                e.start_reschedule !== null ? e.start_reschedule : "-",
              registration_number:
                e.registration_number !== null
                  ? e.registration_number.toUpperCase()
                  : "-",
              registration_group:
                e.registration_group !== null
                  ? e.registration_group.toUpperCase()
                  : "-",
              booking_date: bookingDateFormat,
              order_number:
                e.order_number !== null ? e.order_number.toUpperCase() : "-",
              id_number:
                typeof e.id_number !== "undefined"
                  ? e.id_number.toUpperCase()
                  : "",
              patient:
                typeof e.patient !== "undefined" ? e.patient.toUpperCase() : "",
              department:
                e.department !== null ? e.department.toUpperCase() : "",
              job_position:
                e.job_position !== null ? e.job_position.toUpperCase() : "-",
              plant: e.plant !== null ? e.plant.toUpperCase() : "-",
              type: typeof e.type !== "undefined" ? e.type.toUpperCase() : "",
              item_name:
                typeof e.item_name !== "undefined"
                  ? e.item_name.toUpperCase()
                  : "",
              status:
                typeof e.status !== "undefined" ? e.status.toUpperCase() : "",
              amount_total:
                e.amount_total !== "0" ? e.amount_total.toUpperCase() : "-",
              order_id: typeof e.order_id !== "undefined" ? e.order_id : "",
            });
          });
         
          this.datasPatient = new_data;

          this.itemsTotalPopup = res.total;
          this.itemLengthPopup = Math.ceil(
            this.itemsTotalPopup / showPagePopup
          );
          if (this.itemShowStopPopup < 0) {
            this.itemShowStopPopup = res.total;
          }
          if (this.itemShowStopPopup > res.total) {
            this.itemShowStopPopup = res.total;
          }
          this.myloadingvariablePopup = false;
        }
      } catch (error) {
        console.log(error);
      }
    },
    downloadLab(param, type, isProduct) {
      if (isProduct) {
        API.get(
          `${process.env.VUE_APP_API_TRUST}diagnosis/get-labreport?order_number=${param}&type=${type}`,
          {
            Authorization: `Bearer ${Auth.getToken()}`,
          }
        ).then((res) => {
          this.download = res;
          window.open(this.download.linkDownload, "_blank");
        });
      } else {
        API.get(
          `${process.env.VUE_APP_API_TRUST}diagnosis/get-mcureport?order_number=${param}&type=${type}`,
          {
            Authorization: `Bearer ${Auth.getToken()}`,
          }
        ).then((res) => {
          this.download = res;
          window.open(this.download.linkDownload, "_blank");
        });
      }
    },
    // editItem(item) {
    //   this.$router.push(
    //     `/${this.$i18n.locale}/company/office-care/detail/${item.id}`
    //   );
    // },
    // deleteItem(item) {
    //   this.editedIndex = this.datas.indexOf(item);
    //   this.editedItem = Object.assign({}, item);
    //   this.dialogDelete = true;
    // },

    // deleteItemConfirm() {
    //   let vm = this;
    //   try {
    //     let form = { person_id: this.datas[this.editedIndex].id };
    //     (async function () {
    //       const res = await API.del(
    //         `${process.env.VUE_APP_API_TRUST}employee/delete`,
    //         {
    //           "Content-Type": "application/json",
    //           Authorization: `Bearer ${Auth.getToken()}`,
    //         },
    //         [form]
    //       );

    //       if (res.statusCode === 200) {
    //         vm.toast("success", vm.$t("success_insert_data"));
    //         setTimeout(function () {
    //           vm.getDataEmployee();
    //         }, 200);
    //       } else if (res.statusCode == 403) {
    //         Object.keys(res.errors).forEach((element) => {
    //           vm.toast(
    //             "error",
    //             typeof res.errors[element] == "object"
    //               ? res.errors[element][0]
    //               : res.errors[element]
    //           );
    //         });
    //       } else {
    //         vm.$swal({
    //           icon: "error",
    //           text: vm.$t("error_try_again_latter"),
    //         });
    //       }
    //     })();
    //   } catch (error) {
    //     this.$swal({
    //       icon: "error",
    //       text: vm.$t("error_try_again_latter"),
    //     });
    //   }
    //   this.closeDelete();
    // },

    closeEdit() {
      this.dialog_referral = false;
      this.idTemp = "";
    },

    async getDataEmployee() {
      // console.log(`${Auth.getToken()}`);

      // Check result exist
      this.getDataProfile = this.dataProfile.type;

      try {
        this.myloadingvariable = true;
        // pagination showing start of entries
        let startPage = (this.page - 1) * this.perPage;
        let showPage = this.perPage;
        this.itemShowStart = startPage + 1;
        this.itemShowStop = startPage + showPage;

        const res = await API.get(
          `${process.env.VUE_APP_API_TRUST}employee/get-booking-list?query=${this.search}&offset=${startPage}&per_page=${showPage}`,
          {
            "Content-Type": "application/json",
            Authorization: `Bearer ${Auth.getToken()}`,
          }
        );

        // Check result exist
        if (res.results) {
          var new_data = [];
          res.results.map(function (e) {
            var dateCreate =
              typeof e.booking_date !== "undefined" && e.booking_date
                ? moment(e.booking_date).format("DD-MMM-YYYY").toUpperCase()
                : "-";
            new_data.push({
              id: typeof e.id !== "undefined" ? e.id : "",
              registration_group:
                e.registration_group !== null
                  ? e.registration_group.toUpperCase()
                  : "-",
              kode: typeof e.kode !== "undefined" ? e.kode.toUpperCase() : "",
              booking_date: dateCreate,
              plant: e.plant !== null ? e.plant.toUpperCase() : "-",
              type: e.type !== null ? e.type.toUpperCase() : "-",
              item_id: e.item_id !== null ? e.item_id.toUpperCase() : "-",
              item_en: e.item_en !== null ? e.item_en.toUpperCase() : "-",
              status:
                typeof e.status !== "undefined" ? e.status.toUpperCase() : "",
            });
          });

          this.datas = new_data;

          this.itemsTotal = res.total;
          this.itemLength = Math.ceil(this.itemsTotal / showPage);
          if (this.itemShowStop < 0) {
            this.itemShowStop = res.total;
          }
          if (this.itemShowStop > res.total) {
            this.itemShowStop = res.total;
          }
          this.myloadingvariable = false;
        }
      } catch (error) {
        console.log(error);
      }
    },
    toggleDialogOrder(toggle) {
      this.dialog_order = toggle;
    },
    async openDialogResult(item) {
      this.dataDialogOrder.order_number = item.order_number;
      this.dataDialogOrder.type_test = item.type;
      this.getOrderDetailInvoiceMCU(item);
    },
    async getOrderDetailInvoiceMCU() {
      try {
        const res = await API.get(
          `${process.env.VUE_APP_API_TRUST}medical-record/inspection/list?order_number=${this.dataDialogOrder.order_number}`,
          {
            "Content-Type": "application/json",
            Authorization: `Bearer ${Auth.getToken()}`,
          }
        );

        this.dataDialogOrder.results = res.results;

        this.dialog_order = true;
        //cari harga total karena diskon belum kehitung
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>
<style>
.corp-m{
    margin-top: 4px !important;
  }
@media screen and (max-width: 767px) {
  .corp-m{
    margin-top: -10px !important;
  }
}
@media screen and (min-width: 1023px) {
  .sticky-oc1 {
    position: sticky !important;
    position: -webkit-sticky !important;
    left: 0;
    background: #fff;
    z-index: 2;
  }
  .sticky-oc2 {
    position: sticky !important;
    position: -webkit-sticky !important;
    left: 76px;
    background: #fff;
    z-index: 2;
  }
  .sticky-hoc1 {
    position: sticky !important;
    position: -webkit-sticky !important;
    left: 0;
    background: #ddd;
    z-index: 2;
  }
  .sticky-hoc2 {
    position: sticky !important;
    position: -webkit-sticky !important;
    left: 76px;
    background: #ddd;
    z-index: 2;
  }

  .sticky-popup-oc1 {
    position: sticky !important;
    position: -webkit-sticky !important;
    left: 0;
    background: #fff;
    z-index: 4;
  }
  .sticky-popup-oc12 {
    position: sticky !important;
    position: -webkit-sticky !important;
    left: 75px;
    background: #fff;
    z-index: 4;
  }
  .sticky-popup-oc2 {
    position: sticky !important;
    position: -webkit-sticky !important;
    right: 0;
    background: #fff;
    z-index: 4;
  }
  .sticky-popup-hoc1 {
    position: sticky !important;
    position: -webkit-sticky !important;
    left: 0;
    background: #ddd;
    z-index: 4;
  }
  .sticky-popup-hoc12 {
    position: sticky !important;
    position: -webkit-sticky !important;
    left: 75px;
    background: #ddd;
    z-index: 4;
  }
  .sticky-popup-hoc2 {
    position: sticky !important;
    position: -webkit-sticky !important;
    right: 0;
    background: #ddd;
    z-index: 4;
  }
}
.table-officecare-company thead {
  background: #ddd !important;
  font-weight: bold;
  color: #000 !important;
}
.table-officecare-company thead tr th {
  height: 38px !important;
  white-space: nowrap;
}
.table-officecare-company tbody tr td {
  font-size: 12px !important;
  height: 35px !important;
  white-space: nowrap;
}
.table-officecare-company tbody tr:hover {
  background-color: #eee !important;
}
</style>
<style lang="scss" scoped>
@import "@/assets/css/custom.scss";
.package-title {
  font-size: 16px;
  font-weight: bold;
  color: #1a428a;
  margin-bottom: 0;
}
.home {
  display: flex;
  flex-direction: column;
  padding: 1px 0 40px 0;
  &__section {
    padding: 0 20px;
  }
}

@media screen and (min-width: 1023px) {
  .sticky-popup-hd:hover {
    .sticky-popup-oc1 {
      background: #eee !important;
    }
    .sticky-popup-oc2 {
      background: #eee !important;
    }
    .v-data-table
      > .v-data-table__wrapper
      tbody
      tr:first-child:hover
      td:first-child {
      background: #eee !important;
    }
  }

  .hd-oc-sticky:hover {
    .sticky-oc1 {
      background: #eee !important;
    }
    .sticky-oc2 {
      background: #eee !important;
    }
    .v-data-table
      > .v-data-table__wrapper
      tbody
      tr:first-child:hover
      td:first-child {
      background: #eee !important;
    }
  }
}
.items-product {
  width: 380px;
  white-space: break-spaces;
}
.searchField {
  max-width: 500px;
}
.button--active {
  background: $main_3;
  color: white;
}
.button--outline {
  color: $main_3;
  border: 1px solid $main_3;
  background: white;
}
.button--download {
  background: #79c4c6;
  color: white;
}
.button-box {
  box-sizing: border-box;
  border-radius: 10px;
  padding: 8px 16px;
  outline: none;
}
.card--result {
  background: $gray_3;
  padding: 18px;
  border-radius: 16px;
  width: 100%;
  // max-width: 300px;
  color: $gray_2;
  h4 {
    color: $gray_1;
    font-size: 20px;
  }
  p {
    margin: 0;
  }
  .card--result__item {
    padding: 6px 0;
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid $gray_2;
    &:nth-last-child(1) {
      border: none;
    }
  }
}

.status--inprogress {
  color: $yellow_1;
  font-weight: 600;
}
.status--complete {
  color: $green_1;
  font-weight: 600;
}
.status--cancel {
  color: $red_1;
  font-weight: 600;
}
.tabs {
  background: #e5e5e5 !important;
}
.content-table {
  padding: 15px;
  @media screen and (max-width: 960px) {
    padding: 5px 18px 18px 18px;
  }
}
@media screen and (max-width: 480px) {
  .content-table {
    padding: 0px !important;
  }
}
td {
  font-size: 12px !important;
  vertical-align: top !important;
}
.fontSet {
  font-size: 14px !important;
}
.button {
  background-color: #00a4b3 !important;
  border-radius: 5px;
  color: white !important;
  font-size: 14px;
  text-align: center;
  text-transform: capitalize;
  height: 30px !important;
  min-width: 100px !important;
}
a {
  text-decoration: none;
}
.fontSet {
  font-size: 14px !important;
}
.box-page-new {
  margin: 20px;
  border-radius: 4px !important;
}

.v-data-table > .v-data-table__wrapper > table > tbody > tr > td {
  font-size: 12px;
  vertical-align: baseline;
  padding-top: 6px;
  padding-bottom: 6px;
  height: 25px !important;
}

.v-data-table > .v-data-table__wrapper > table > thead > tr > th {
  height: 35px !important;
}
</style>
<style lang="scss">
.footerHide {
  background: none !important;
  align-items: center;
  width: 50px;
  font-size: 12px;
}
.footerHide > .v-data-footer__pagination {
  display: none !important;
}
.footerHide > .v-data-footer__icons-before {
  display: none !important;
}
.footerHide > .v-data-footer__icons-after {
  display: none !important;
}
.payment-info .v-data-table > .v-data-table__wrapper > table > tbody > tr > td {
  font-size: 12px;
  vertical-align: baseline;
  padding-top: 6px;
  padding-bottom: 6px;
}
.v-toolbar__title {
  font-size: 16px;
}
.footer-payment {
  margin: 0 auto !important;
}
.footer-showing {
  font-size: 12px !important;
  margin: 0 auto !important;
}

.footerHide > .v-text-field .v-input__prepend-inner,
.v-text-field .v-input__append-inner {
  padding-left: 0 !important;
}
</style>
<style>
/* Hide scrollbar for Chrome, Safari and Opera */
.table-stick-book-exam .v-data-table__wrapper::-webkit-scrollbar {
  height: 8px;
  width: 0px;
  border: 1px solid #fff;
}

.table-stick-book-exam .v-data-table__wrapper::-webkit-scrollbar-track {
  border-radius: 0;
  background: #eeeeee;
}

.table-stick-book-exam .v-data-table__wrapper::-webkit-scrollbar-thumb {
  border-radius: 0;
  background: #b0b0b0;
}

/* Hide scrollbar for IE, Edge and Firefox */
.table-stick-book-exam .v-data-table__wrapper {
  -ms-overflow-style: none;
  scrollbar-width: none;
}
.super-dense > .v-data-table__wrapper > table > tbody > tr > td,
.super-dense > .v-data-table__wrapper > table > tbody > tr > th,
.super-dense > .v-data-table__wrapper > table > thead > tr > td,
.super-dense > .v-data-table__wrapper > table > thead > tr > th,
.super-dense > .v-data-table__wrapper > table > tfoot > tr > td {
  height: 35px !important;
}
.table-stick-book-exam .v-data-table__wrapper {
  overflow-x: auto;
  overflow-y: auto;
  height: 400px !important;
}
.table-stick-book-exam thead {
  position: sticky;
  inset-block-start: 0;
  top: 0;
  background: #ddd !important;
  font-weight: bold;
  color: #000 !important;
  z-index: 5;
}
</style>
<style>
/* Hide scrollbar for Chrome, Safari and Opera */
.table-stick-book-popup .v-data-table__wrapper::-webkit-scrollbar {
  height: 8px;
  width: 0px;
  border: 1px solid #fff;
}

.table-stick-book-popup .v-data-table__wrapper::-webkit-scrollbar-track {
  border-radius: 0;
  background: #eeeeee;
}

.table-stick-book-popup .v-data-table__wrapper::-webkit-scrollbar-thumb {
  border-radius: 0;
  background: #b0b0b0;
}

/* Hide scrollbar for IE, Edge and Firefox */
.table-stick-book-popup .v-data-table__wrapper {
  -ms-overflow-style: none;
  scrollbar-width: none;
}
.super-dense > .v-data-table__wrapper > table > tbody > tr > td,
.super-dense > .v-data-table__wrapper > table > tbody > tr > th,
.super-dense > .v-data-table__wrapper > table > thead > tr > td,
.super-dense > .v-data-table__wrapper > table > thead > tr > th,
.super-dense > .v-data-table__wrapper > table > tfoot > tr > td {
  height: 35px !important;
}
.table-stick-book-popup .v-data-table__wrapper {
  overflow-x: auto;
  overflow-y: auto;
  height: 350px !important;
}
.table-stick-book-popup thead {
  position: sticky;
  inset-block-start: 0;
  top: 0;
  background: #ddd !important;
  font-weight: bold;
  color: #000 !important;
  z-index: 5;
}
</style>
