<template>
  <v-form ref="formReschedule">
    <v-card>
      <v-card-title>{{ $t("page.profile.reschedule_book") }}</v-card-title>
      <v-divider class="mt-1"></v-divider>
      <v-card-text
        style="padding-bottom: 3px !important"
        v-if="!checkReschedule"
      >
        <p style="font-size: 14px; color: #808080; margin-bottom: 0px">
          {{ $t("field.cant_reschedule") }}
        </p>
      </v-card-text>
      <v-card-text style="padding-bottom: 3px !important" v-else>
        <p style="font-size: 12px; color: #808080; margin-bottom: 0px">
          *{{ $t("field.rules_rescheduled") }}
        </p>
        <p style="font-size: 12px; color: #808080; margin-bottom: 0px">
          *{{ $t("field.max_rescheduled_company") }}
          <b>{{ max_reschedule }}</b> {{ $t("field.times") }}
        </p>
        <p style="font-size: 12px; color: #808080">
          *{{ $t("field.attemp_rescheduled") }}
          <b style="color: red !important">{{ reschedule_attempt }}</b>
          {{ $t("field.total_changed_reschedule") }}
        </p>
        <v-menu
          v-model="opendatepicker"
          :close-on-content-click="false"
          max-width="290"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="dateFormatted"
              :label="$t('page.profile.reschedule_date')"
              outlined
              append-icon="mdi-calendar-range"
              dense
              readonly
              v-bind="attrs"
              v-on="on"
              :rules="[
                (v) =>
                  !!v ||
                  `${$t('page.profile.reschedule_date')} ${$t('is_required')}`,
              ]"
            ></v-text-field>
          </template>
          <v-date-picker
            :locale="$i18n.locale"
            v-model="payload.reschedule_date"
            @change="updDate()"
            :allowed-dates="allowedDates"
            @update:picker-date="pickerUpdate($event)"
            :min="minDate"
            :max="maxDate"
            color="#00a4b3"
            header-color="#00a4b3"
          >
          </v-date-picker>
        </v-menu>
      </v-card-text>
      <v-divider class="mt-1"></v-divider>
      <v-card-actions class="justify-end" v-if="!checkReschedule">
        <v-btn text @click="$emit('closeReschedule')">
          {{ $t("close") }}
        </v-btn>
      </v-card-actions>
      <v-card-actions class="justify-end" v-else>
        <v-btn text @click="$emit('closeReschedule')">
          {{ $t("cancel") }}
        </v-btn>
        <v-btn color="#00a4b3" text @click="saveEdit">
          {{ $t("save") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-form>
</template>

<script>
import API from "@/service/api_service";
import Auth from "@/service/auth_service";
import moment from "moment-timezone";
export default {
  data: () => ({
    payload: {
      registration_number: "",
      reschedule_date: "",
    },
    opendatepicker: false,
    dateParam: "",
    minDate: "",
    maxDate: "",
    max_reschedule: "",
    reschedule_attempt: "",
    openNotifCant: false,
    availableDates: [],
    dataTempResch: {},
    dataBranch:[],
    holidayThisMonth:[]
  }),
  props: {
    data: { type: Object, default: null },
  },
  computed: {
    dateFormatted() {
      return this.formatDate(this.payload.reschedule_date);
    },
    checkReschedule() {
      
      var todayBooking = moment().format("YYYY-MM-DD");
      var bookingDateNew = moment(this.dataTempResch.booking_date).format("YYYY-MM-DD");
      
      var isafter = moment(bookingDateNew).isAfter(todayBooking);

      if (
        isafter != true
      ) {
        return false;
      }
      else{
        return true;
      }
    },
  },
  watch: {
    data(val) {
      this.dataTempResch = val;
      this.setStartData();
    },
  },
  created() {
    this.dataTempResch = this.data;
   
    this.setStartData();
  },
  async mounted() {
    // branch
    await API.get(`${process.env.VUE_APP_API_TRUST}plant/get?show_opening_hours=true `, {
      Authorization: `Bearer ${Auth.getToken()}`,
    }).then((resp) => {
      const selectBranch = resp.results.filter(getBranch => {
          return getBranch.name == this.dataTempResch.plant;
        });
      this.dataBranch = selectBranch;
      
     
    });
  },
  methods: {
    // start holiday
    getHoliday() {
     
      if (this.dataBranch.length > 0 ) {
        return this.dataBranch[0].upcoming_holidays;
      }
      return [];
    },
    // end holiday
      // start enable days
      getEnableDays() {
     
      let enableDays = [];
      
        const days = [
          "Minggu",
          "Senin",
          "Selasa",
          "Rabu",
          "Kamis",
          "Jumat",
          "Sabtu",
        ];
        const { opening_hours } = this.dataBranch[0];
        for (let i = 0; i < days.length; i++) {
          const indexDays = opening_hours.findIndex(
            item => item.day == days[i]
          );
          if (indexDays > -1) {
            enableDays.push(i);
          }
        }
      
      return enableDays;
    },
    // end enable days
    
    setStartData() {
      this.dateParam = moment(this.dataTempResch.booking_date).format(
        "YYYY-MM-DD"
      );

      this.maxDate = moment(this.dataTempResch.booking_date)
        .add(this.dataTempResch.expired_days, "days")
        .format("YYYY-MM-DD");

      this.payload.reschedule_date = moment(
        this.dataTempResch.booking_date
      ).format("YYYY-MM-DD");

      this.minDate = moment(this.dataTempResch.booking_date)
        .add(1, "days")
        .format("YYYY-MM-DD");

      this.payload.registration_number = this.dataTempResch.registration_number;
      this.max_reschedule = this.dataTempResch.max_reschedule;
      this.reschedule_attempt = this.dataTempResch.reschedule_attempt;
    },
    // start available date
    allowedDates(a) {
      return this.availableDates.includes(a);
    },
    pickerUpdate: function (val) {
    
      this.pickerValue = val;
      let totalDay = moment(val, "YYYY-MM").daysInMonth();

      let availableDates = [];

      let monthNow = moment().format("M");
      let monthSelected = moment(val).format("M");
      let day;

      if (monthNow == monthSelected) day = moment().format("D");
      else day = 1;
      for (let i = day; i <= totalDay; i++) {
        let date = moment(val, "YYYY-MM")
          .month(val.split("-")[1] - 1)
          .date(i)
          .format("YYYY-MM-DD");
        if (this.getEnableDays().includes(-1)) {
          availableDates.push(date);
        } else {
          if (this.getEnableDays().includes(moment(date).day()))
            availableDates.push(date);
        }
      }

      this.holidayThisMonth = this.getHoliday().filter(
        item => moment(item.date).format("YYYY-MM") == val
      );

      for (const holiday of this.holidayThisMonth) {
        const index = availableDates.indexOf(holiday.date);
        if (index !== -1) {
          availableDates.splice(index, 1);
        }
      }

      this.availableDates = availableDates;
      this.allowedDates();
    },
    // end available date
    formatDate(date) {
      if (!date) return null;
      return moment(date)
        .locale(this.$i18n.locale)
        .format("DD-MMM-YYYY")
        .toUpperCase();
    },
    updDate() {
      this.$store.state.date = moment(this.dateFormatted)
        .format("YYYY-MM-DD")
        .toUpperCase();
    },

    async saveEdit() {
      if (this.$refs.formReschedule.validate() == true) {
        try {
          let form = {
            registration_number:
              typeof this.payload.registration_number !== "undefined"
                ? this.payload.registration_number
                : "",
            date:
              typeof this.payload.reschedule_date !== "undefined"
                ? this.payload.reschedule_date
                : "",
          };
          // Begin api request
          const res = await API.post(
            `${process.env.VUE_APP_API_TRUST}employee/reschedule-booking`,
            {
              "Content-Type": "application/json",
              Authorization: `Bearer ${Auth.getToken()}`,
            },
            [form]
          );

          if (res.statusCode === 200) {
            this.toast("success", res.message);
            this.$emit("updateData");
            this.$emit("closeReschedule");
            window.location.href = `/company/office-care`;
          } else if (res.statusCode == 403) {
            Object.keys(res.errors).forEach((element) => {
              this.toast(
                "error",
                typeof res.errors[element] == "object"
                  ? res.errors[element][0]
                  : res.errors[element]
              );
            });
          } else if (res.statusCode == 400) {
            Object.keys(res.errors).forEach((element) => {
              this.toast(
                "error",
                typeof res.errors[element] == "object"
                  ? res.errors[element][0]
                  : res.errors[element]
              );
            });
            this.setDataBasic = [];
          } else {
            this.$swal({
              icon: "error",
              text: res.message,
            });
          }
        } catch (error) {
          this.$swal({
            icon: "error",
            text: this.$t("error_try_again_latter"),
          });
        }
      }
    },
    toast(status, messages) {
      this.$swal
        .mixin({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", this.$swal.stopTimer);
            toast.addEventListener("mouseleave", this.$swal.resumeTimer);
          },
        })
        .fire({
          icon: status,
          title: messages,
        });
    },
  },
};
</script>

<style></style>
